@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.min.css";

@import "styles/variables.scss";
@import "styles/pages.scss";
@import 'react-date-range/dist/styles.css'; // main style file
@import 'react-date-range/dist/theme/default.css'; // theme css file

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: 'Trebuchet MS', sans-serif;
}

a {
  transition: all ease-in-out 0.3s;
}

img {
  max-width: 100%;
}

button {
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

.chart-container {
  position: relative;
  height: 60vh;
  margin-bottom: 20px;
  //max-width: 750px;

  @include media-breakpoint-up(md) {
    height: 60vh;
    margin-bottom: 30px;

    max-width: 750px;
  }

  &.onboard {
    margin-bottom: 10px;
  }
}

.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  height: 200px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


//canvas {
//  margin-bottom: 20px;
//  max-width: 750px;
//
//  @include media-breakpoint-up(md) {
//    margin-bottom: 30px;
//  }
//}

h2 {
  color: $color-blue1;
  font-weight: 300;
  font-family: $roboto-light;
  font-size: 23px;

  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }
}

h4 {
  color: $color-black;
  font-family: 'Trebuchet MS', sans-serif;

  &.number-big {
    font-size: 40px;
    font-weight: 700;
    font-family: 'Trebuchet MS', sans-serif;
    sup {
      font-size: 20px;
    }

    @include media-breakpoint-up(md) {
      font-size: 58px;

      sup {
        top: -30px;
      }
    }
  }
}

p {
  &.number-big {
    font-weight: 700;
    color: $color-black;
    margin-bottom: 0;
    font-size: 25px;

   @include media-breakpoint-up(md) {
      font-size: 32px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 38px;
    }
  }
}

.MuiFormControlLabel-root {
  .MuiTypography-root {
    font-weight: 300;
    font-size: 17px;
    color: $color-grey;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $color-blue;
  }

  .MuiCheckbox-root {
    color: $color-blue;
  }
}

.MuiFormControl-root {
  
  label {
    font-weight: 300;
    font-size: 17px;
    color: #848484;

    &.Mui-focused {
      color: $color-blue;
    }
  }

  .MuiInput-underline {
    &:after,
    &:before {
      border-bottom: 1px solid $color-blue;
    }
  }
}

.nav-filter {
  .nav-link {
    background: transparent;
    padding:  0px;
    color: $color-blue;
    font-size: 10px;
    margin-top: 20px;
    cursor: pointer;
    &.active {
      font-weight: 700;
    }
  }
}

.dialog-custom,
.MuiBox-root {
  .MuiDialogContent-root {
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
      overflow: hidden;
    }
  }

  .MuiPaper-rounded {
    border-radius: 13px;
  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .MuiDialog-paperWidthMd {
    margin: 15px;

    @include media-breakpoint-up(md) {
      min-width: 500px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 650px;
    }

    .map-container {
      min-height: 195px;
    }

    .MuiDialogTitle-root {
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(230, 230, 230, 1) 53%, rgba(255, 255, 255, 1) 100%);

      span {
        color: $color-blue1;
        font-weight: 300;
        font-family: $roboto-light;
        font-size: 23px;

        /*@include media-breakpoint-up(lg) {
          font-size: 28px;
        }*/
      }

      p {
        font-family: $roboto-reguler;
        font-size: 13px;
        color: $color-black;

        @include media-breakpoint-up(lg) {
          font-size: 15px;;
        }
      }
    }
  }

  label.title {
    font-family: $roboto-medium;
    color: $color-grey;
    font-size: 13px;
    margin-bottom: 0;
    display: block;

    @include media-breakpoint-up(lg) {
      font-size: 17px;;
    }
  }

  i {
    &.status {
      font-style: normal;
    }
  }

  p {
    font-size: 13px;
    color: $color-black;

    @include media-breakpoint-up(lg) {
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.text-danger {
  font-size: 14px;
}

.dashboard-container {
  margin-left: 200px;
  padding: 20px;
}

.capitalize-text {
  text-transform: capitalize;
}

.text-title{
  font-size: 30px;
  color: $color-black;
  font-style: normal;
  font-weight: 5;
  font-family: 'Trebuchet MS', sans-serif;
  margin-top: 90px;
  margin-left: 50px;
}

.text-subTitle{
  font-size: 23px;
  color: #000000;
  margin-left: 10px ;
  margin-top: 80px;
  font-family: Helvetica, sans-serif;
  font-weight: bolder;
}
.text-subTitle2{
  font-size: 23px;
  color: #000000;
  margin-left: 0px ;
  font-family: Helvetica, sans-serif;
  font-weight: bolder;
}

.icon-metro{
  height:20px;
  width:30px;
  left:0px;
  margin-left:25px ;
  margin-top:5px;
}
.icon-logo{
  height:35px;
  width:80px;
  margin-left:10px ;
  margin-top:0px;
}

.text-name{
  color:$color-black;
  font-size:12px;
  margin-top:10px;
  font-family: 'Trebuchet MS', sans-serif;
  text-align:flex-end;
}
.text-email{
  color:#a1a1a1;
  font-size:12px;
  margin-top:-7px;
  font-family: 'Trebuchet MS', sans-serif;
  text-align:flex-end;
}

.text-gray{
  color:#8a8a8a;
  font-size:15px;
  margin-left: 3px;
  font-family: 'Trebuchet MS', sans-serif;
  margin-top:-7px;
  text-align:flex-end;
}
.text-end{
  color:#8a8a8a;
  font-size:14px;
  margin-top: 15px;
  font-family: 'Trebuchet MS', sans-serif;
  text-align:flex-end;
}
.text-gray-2{
  color:#363636;
  font-size:15px;
  font-family: 'Trebuchet MS', sans-serif;
  margin-top: 15px;
}

.text-black{
  color:#000000;
  font-size:15px;
  font-family: 'Trebuchet MS', sans-serif;
  margin-top: 15px;
}
.text-small{
  color:#a1a1a1;
  font-size:12px;
  font-family: 'Trebuchet MS', sans-serif;
  &-black{
    color:#000000;
    font-size:11px;
    margin-bottom: 0px;
  }
}
.text{
  font-size:15px;
  margin-bottom: 0px;
  font-weight: bolder;
  font-family: 'Trebuchet MS', sans-serif;
  &-black2{
    color:#000000;
  }
  &-yellow{
    color:#b48611;
    background-color: #fff6dc;
    border-radius: 2px;
    margin-bottom: -3px;
  }
}

.text-click{
  font-size:15px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: bolder;
  padding: 3px;
  cursor: pointer;
  font-family: 'Trebuchet MS', sans-serif;
  &:active {
    background-color: #f6f6f6;
  }
}

.text-red{
  color:#dd4444;//cf2929
  background-color: #ffedf0;
  font-size:15px;
  font-weight: bolder;
  border-radius: 2px;
  margin-bottom: -3px;
  font-family: 'Trebuchet MS', sans-serif;
}
.table-style{
  margin-right:20px;
  margin-left:20px;
  margin-top: 20px;
  overflow-x: auto;
}
.cell-style{
  padding-top:2px;
  padding-bottom:2px;
  border-right:1px solid #b2b2b2;
}

.container {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-container1{
  background-color: rgb(255, 255, 255);
  border: 1px solid $color-grey2 ;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.img-overview{
  height: 130px;
  width: 200px;
  border-radius: 8px;
}
.img-plate{
  height: 40px;
  width: 100px;
  border-radius: 8px;
}
.search-bar {
  color: #8a8a8a;
  background-color: $color-grey1;
  border-radius: 50px;
  margin-top: -5px;
}
.select-style{
  border: 1px solid $color-grey2 ;
  border-radius: 10px;
}

.right-dialog{
  padding: 20px;
  width: 50%;
  height: auto;
  right: 5%;
  top: 130px;
  position: fixed;
  z-index: 100;
  border-radius: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #000000; //#00000029
  opacity: 1;
  margin-top:-5px;
  margin-right: -20px;
  //display: inline-table;
}
.user-dialog{
  padding: 10px;
  width: 13%;
  height: auto;
  right:2%;
  top: 8%;
  position: absolute;
  z-index: 1500;
  border-radius: 4px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000065; //#00000029
  opacity: 1;
}
.rc_root.green {
  --rc-hsl-primary-hue: 160deg;
}

.text-edit{
  margin-top: -10px;
  padding: 10px;
  width: 100%;
  bottom: 0%;
  background: #FFFFFF;
  border: 1px solid #E3E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  &-error{
    margin-top: -10px;
    padding: 10px;
    width: 100%;
    bottom: 0%;
    background: #FFFFFF;
    border: 1px solid #F85E5E;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
.toast-error{
  background: #F85E5E;
  padding-left: 15px;
  margin-left: -450px;
  color: #FFFFFF;
  font: normal normal bold Lato;
  width:400%;
}


.selectbox {
	width: 50%;
	margin: auto;
	position: relative;
}

.select {
	background: #fff;
	width: 100%;
	box-shadow: 0px 0px 0px rgba(0, 0, 0, .16);
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: .2s ease all;
	margin-bottom: 30px;
	padding: 30px;
	position: relative;
	z-index: 200;
	border: 2px solid transparent;
}

.image-detail{
  width: 100%;
  cursor: pointer;
  height: 50%;
  border-radius:10px;
}