$color-blue       : #248ceb;
$color-blue1       : #248ceb;
$color-light-blue      : #f2f9ff;
$color-dark-blue      : #175c9c;
$color-black      : #000000;
$color-grey       : #B2B2B2;
$color-grey1       : #F1F1F1;
$color-grey2       : #b2b2b2;
$color-grey3       : #cfcfcf;
$color-lightgrey  : rgba(0, 0, 0, 0.16);
$color-white      : #FFFFFF;
$color-grey-white : #FAFAFA;
$color-green      : #B9D4E0;


//Fonts
$roboto-light    : 'Roboto Light';
$roboto-medium   : 'Roboto Medium';
$roboto-reguler  : 'Roboto Reguler';
$gibson          : 'Gibson';
$gibson-semi     : 'Gibson Semibold';
$gibson-light    : 'Gibson Light';
$source-sans     : 'Source Sans Pro';
$clear-sans      : 'Clear Sans';

.btn {
  border: none;
  padding: 3px 15px;
  font-size: 14px;
  font-family: $source-sans;

  /*@include media-breakpoint-up(lg){
    font-size: 15px;
    padding: 3px 30px;
  }*/

  &:hover,
  &:focus {
    outline: none;
  }

  &-blue {
    background: $color-blue;
    color: $color-white;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bolder;
    border: 1px solid $color-blue;
    border-radius: 3px;

    .login & {
      padding: 15px;
      width: 100%;

      /*@include media-breakpoint-up(md){
        font-size: 20px;
      }*/
    }

    &:hover {
      background: transparent;
      color: $color-blue;
      border: 1px solid;
    }

    &-trans {
      background: transparent;
      font-family: 'Trebuchet MS', sans-serif;
      color: $color-blue;
      border: 0px solid;
      margin-right: 10px;
      border-radius: 1px;

      &:hover {
        background: transparent;
        color: $color-dark-blue;
        border: 1px solid $color-blue;
      }
    }
  }

  &-ligth-blue{
    background: transparent;
    color: $color-grey;
    border: 0px solid $color-grey;
    font: bolder;
    font-family: 'Trebuchet MS', sans-serif;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 3px;
    &:hover {
      background: $color-light-blue;
      color: $color-blue;
      margin-left: 3px;
      border: 0px solid;
    }
  }

  &-text-black{
    background: transparent;
    color: $color-black;
    border: 0px solid $color-grey;
    font-family: 'Trebuchet MS', sans-serif;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    &:hover {
      background: $color-light-blue;
      color: $color-blue;
      border: 0px solid;
    }
  }
  &-outline{
    background: transparent;
    color: $color-black;
    font-size: 13px;
    font-weight: bolder;
    border: 1px solid $color-black;
    border-bottom: 2px solid $color-black;
    border-left: 2px solid $color-black;
    font-family: 'Trebuchet MS', sans-serif;
    border-radius: 4px;
    margin-left: 25px;
    width: 25%;
    margin-bottom: 10px;
    margin-top: -10px;
    padding: 10px;
    cursor: pointer;
    &:active {
      border-top: 2px solid $color-black;
      border-right: 2px solid $color-black;
      border-bottom: 1px solid $color-black;
      border-left: 1px solid $color-black;
    }
  }
  &.full {
    width: 100%;
  }

  &-close {
    background: transparent;
    border: none;
    color: $color-black;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.text {
  &-blue {
    color: $color-blue !important;
    margin-top: 32px;
    font-size: 13px;
    margin-left: -50px;
    font-weight: bolder;
    cursor: pointer;
    font-family: 'Trebuchet MS', sans-serif;
  }
  &-alert{
    background-color: #FF0000C4;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    position: sticky;
    padding: 2px;
    z-index: 100;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &-accept{
    background-color: #1e7c2393;//0059ffb2
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    position: sticky;
    padding: 2px;
    z-index: 100;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &-warning{
    background-color: #abad0393;//0059ffb2
    color: #ffffff;
    text-align: center;
    width: 100%;
    position: sticky;
    padding: 2px;
    z-index: 100;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &-dark-blue{
    background: transparent;
    color: $color-dark-blue;
    font: bolder;
    font-size: 13px;
    font-family: 'Trebuchet MS', sans-serif;
  }
}

.table{
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
  width: '100%'
}
.head-theme{
  margin-left: 1px;
  margin-right: 1px;
  background-color: #fafafa;
  border-color: #FFFFFF;
}
.row-head{
  padding-top:0px;
  padding-bottom:0px;
}

.table-list {
  thead{
    tr {
      border-bottom: 1px solid;

      th {
        font-size: 15px;
        color: $color-black;
        font-family: $clear-sans;
        padding: 5px 10px;
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      background-color: $color-grey-white;

      &:hover,
      &.Mui-selected{
        background-color: $color-green;
      }

      &:nth-child(even) {
        background-color: $color-white;

        &:hover,
        &.Mui-selected{
          background-color: $color-green;
        }
      }

      td {
        border: none;
        color: $color-black;
        font-family: $clear-sans;
        padding: 0px 0px;
        letter-spacing: 0;

        strong {
          font-family: $gibson-semi;
        }
      }
    }
  }
}